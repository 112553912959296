import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const FakeFooter = ({
  ...props
}) => {


  return (
    <Container>
      
    </Container>
  )
};

const Container = styled(FlexContainer)`
  background-color: ${colors.altBlue};
  padding: 80px 0;
  height: 150px;

  @media (max-width: 479px) {
    display: none;
  }
`;

export default FakeFooter;
