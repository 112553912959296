import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const ProjectBox = ({
  image,
  companyName,
  projectType,
  ...props
}) => {

  return (
    <Container flexDirection="column">
      <ProjectScreen
        src={`${process.env.PUBLIC_URL}${image}`}
        {...props}
      >
      </ProjectScreen>
      <ProjectTitle {...props}>{companyName}</ProjectTitle>
      <ProjectType {...props}>{projectType}</ProjectType>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  text-align: left;
`

const ProjectScreen = styled(FlexContainer)`
  width: 247px;
  height: 161px;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
`

const ProjectTitle = styled.h3`
  color: white;
  font-size: 19px;
  line-height: 18px;
  margin-bottom: 8px;
  text-align: left;
`;

const ProjectType = styled.p`
  font-size: 10px;
  letter-spacing: 1.87px;
  text-transform: uppercase;
  color: ${colors.mainYellow};
`

export default ProjectBox;
