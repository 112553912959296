import React from "react";
import styled from "styled-components";
import { colors } from "../styleConstants";
import FlexContainer from "../elements/FlexContainer";

import BioBox from "./BioBox";

const SectionTeam = (props) => {
  return (
    <Container flexDirection="column" alignItems="center">
      <Caption>Our team</Caption>
      {/* what we've done? */}
      <Header>Say hello to your new partners</Header>
      <BoxRow justify="center" wrap="wrap">
        <BioBox
          name="Mike Knerr"
          image={`${process.env.PUBLIC_URL}/images/team/MikeKnerr.jpg`}
          role="CEO / Web Developer"
          bio="Founder and full-stack web developer with a background in project
          management and software implementation."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Geoffrey Papin"
          image={`${process.env.PUBLIC_URL}/images/team/GeoffreyPapin.jpeg`}
          role="Tech Lead"
          bio="Full-stack web developer and DevOps Engineer with experience in
          public and private sectors."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Shailesh Choubey"
          image={`${process.env.PUBLIC_URL}/images/team/ShaileshChoubey.jpg`}
          role="Project Manager"
          bio=""
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Pooja Chawla"
          image={`${process.env.PUBLIC_URL}/images/team/PoojaChawla.png`}
          role="Web/Mobile Developer"
          bio="Full-stack web developer, mobile app developer and a software engineer with experience in diverse industries."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Rachel Scott"
          image={`${process.env.PUBLIC_URL}/images/team/RachelScott.jpg`}
          role="UI/UX Designer"
          bio=""
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Charly Martin"
          image={`${process.env.PUBLIC_URL}/images/team/CharlyMartin.jpg`}
          role="Senior Frontend Developer"
          bio=""
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Alexandru Dragomir"
          image={`${process.env.PUBLIC_URL}/images/team/AlexDragomir.jpg`}
          role="Senior Backend Developer"
          bio=""
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Adam Guldemann"
          image={`${process.env.PUBLIC_URL}/images/team/AdamGuldemann.jpg`}
          role="Senior Web Developer / DevOps Engineer"
          bio="Full-stack web developer and infrastructure guru skilled at migrating complex/legacy systems
          to modern technologies."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        <BioBox
          name="Deniz Onat"
          image={`${process.env.PUBLIC_URL}/images/team/DenizOnat.png`}
          role="UI/UX Designer"
          // bio="Full-stack web developer with experience leading large teams
          // of developers on a diverse range of projects."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        />
        {/* <BioBox
          name="Derrick Sun"
          image={`${process.env.PUBLIC_URL}/images/team/DerrickSun.jpg`}
          role="UX/UI Designer"
          bio="Multi-disciplinary designer,
          specializing in web design, UX/UI design, branding, and marketing."
          social={{
            twitter: "www.google.com",
            facebook: "www.google.com",
            linkedin: "www.google.com",
          }}
        /> */}
      </BoxRow>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  @media (max-width: 479px) {
    display: none;
  }
`;
const BoxRow = styled(FlexContainer)`
  margin: 0 -18px -18px 0;
  width: calc(100% + 18px);

  > * {
    margin: 0 18px 18px 0;
  }
  /* >:not(:last-child) {
    margin-right: 18px;
  }

  @media (max-width: 479px) {
    >:not(:last-child) {
      margin-right: 0;
    }
  } */
`;

const Header = styled.h2`
  color: #fff;
  text-align: center;
  margin-bottom: 38px;
`;

const Caption = styled.h3`
  color: ${colors.mainYellow};
`;

export default SectionTeam;
