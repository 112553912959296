import React, { useState } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';
import ThankYouPage from './pages/ThankYouPage';
import PortfolioPage from './pages/PortfolioPage';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  const [contactEmail, setContactEmail] = useState('');
  return (
    <div className="App">
      <Switch>
        <Route exact path="/"
          render={(props) => <LandingPage {...props} setContactEmail={setContactEmail} /> }
        />
        <Route exact path="/contact"
          render={(props) => <ContactPage {...props} email={contactEmail} /> }
        />
        <Route exact path="/thankyou"
          render={(props) => <ThankYouPage {...props} /> }
        />
        <Route exact path="/portfolio"
          render={(props) => <PortfolioPage {...props} setContactEmail={setContactEmail} /> }
        />
        <Route exact path="/privacy-policy"
          render={(props) => <PrivacyPolicy {...props} /> }
        />
      </Switch>
    </div>
  );
}

export default App;
