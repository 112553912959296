
import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const PortfolioSubsection = ({
  size,
  backgroundImage,
  header,
  text,
  ...props
}) => {


  return (
    <Container
      flexDirection="column"
      alignItems="center"
      justify="center"
      size={size}
      image={backgroundImage}
    >
      <Header>{header}</Header>
      <Text>{text}</Text>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  flex-grow: 1;
  text-align: ${props => props.size === 'small' ? 'left' : 'center'};
  /* flex-basis: 40%; */
  @media (max-width: 479px) {
    padding: 40px 0;
  }
`;

const Text = styled.h4`
  color: white;
  max-width: ${props => props.size === 'small' ? '80%' : '50%'};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @media (max-width: 479px) {
    text-align: center;
    max-width: 60%;
    line-height: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.mainYellow};
  margin-bottom: 16px;

  @media (max-width: 479px) {
    text-align: center;
  }
`;

export default PortfolioSubsection;
