import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';

const TextInput = ({ label, hideLabel, error, ...props }) => (
  <Container flexDirection="column" {...props}>
    {label && <Label>{label}</Label>}
    <Input type={props.type || 'text'} error={error} {...props} />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Container>
);

const Container = styled(FlexContainer)`
  width: ${props =>
    props.width ? props.width : props.small ? '161px' : '100%'};
`;

const Input = styled.input`
  box-sizing: border-box;
  height: ${props => props.height || '50px'};
  width: 100%;
  border: ${props =>
    props.error ? `1px solid ${colors.mainYellow}` : `2px solid ${colors.mainBlue}`};
  background-color: ${props => props.backgroundColor || 'rgba(38, 38, 94, 0.3)'};
  margin: 0;
  padding: 0 12px;
  color: ${props => props.color || 'rgba(255, 255, 255, 0.75)'};
  font-size: ${props => props.fontSize || '17px'};
  line-height: ${props => (props.small ? '18px' : '20px')};
  border-radius: ${props => props.borderRadius || '8px'};

  &:focus {
    border-bottom: ${props =>
      props.borderNone ? `2px solid ${colors.mainBlue}` : `1px solid ${colors.mainYellow}`};
    box-shadow: ${props =>
      props.borderNone ? 'none' : `0 2px 4px 0 rgba(0, 0, 0, 0.1)`};
  }
  &:disabled {
    background-color: ${colors.primary050};
    color: ${colors.primary300};
    border: ${colors.primary300};
  }

  &::placeholder {
    color: ${colors.primary400};
  }
`;
const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${props => (props.center ? 'center' : 'right')};
  margin: ${props => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

export default TextInput;
