import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';

const TextBox = ({ label, hideLabel, error, defaultHeight, ...props }) => {
  const [height, setHeight] = useState(defaultHeight || '100px');

  const handleChange = e => {
    setHeight(e.target.style.scrollHeight + 'px');
  };

  return (
    <Container flexDirection="column" {...props}>
      {label && <Label>{label}</Label>}
      <TextArea
        error={error}
        onChange={handleChange}
        height={height}
        defaultHeight={defaultHeight}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: ${props =>
    props.width ? props.width : props.small ? '161px' : '100%'};
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  min-height: 250px;
  max-height: 500px;
  overflow: scroll;
  height: ${props => props.height};
  width: 100%;
  border: ${props =>
    props.error ? `1px solid ${colors.mainYellow}` : `2px solid ${colors.mainBlue}`};
  background-color: rgba(38, 38, 94, 0.3);
  margin: 0;
  padding: 12px;
  color: rgba(255, 255, 255, 0.75);
  font-size: ${props => (props.small ? '15px' : '17px')};
  line-height: ${props => (props.small ? '18px' : '20px')};
  border-radius: 4px;
  resize: none;

  &:focus {
    border-bottom: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.mainYellow}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    background-color: ${colors.primary050};
    color: ${colors.primary300};
    border: ${colors.primary300};
  }

  &::placeholder {
    color: ${colors.primary400};
  }
`;
const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${props => (props.center ? 'center' : 'right')};
  margin: ${props => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

export default TextBox;
