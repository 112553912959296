import { useState } from 'react';

const useFormValues = (initialValue={}) => {

  const [values, setValues] = useState(initialValue);

  const handleChange = (e) => {
    e.persist();
    setValues(values => ({ ...values,
      [e.target.name]: e.target.type === 'number' ?
      parseInt(e.target.value) :
      e.target.value }));
  };

  const handleRadio = (name, value) => {
    setValues(values => ({ ...values,
      [name]: value }));
  };
  const handleValueChanged = (name, value) => {
    setValues(values => ({ ...values, [name]: parseInt(value)}));
  };
  return {
    handleChange,
    handleRadio,
    handleValueChanged,
    values,
    setValues
  }
}

export default useFormValues;
