import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import TextInput from '../elements/TextInput';

import useFormValues from '../hooks/useFormValues';
import useReactRouter from 'use-react-router';

const FooterCTA = ({
  setContactEmail,
  ...props
}) => {
  const {
    values,
    handleChange
  } = useFormValues();


  const { history } = useReactRouter();
  const handleClick = () => {
    console.log(values);
    setContactEmail(values.email);
    history.push('/contact');
  }

  return (
    <Container
      flexDirection="column"
      justify="space-between"
    >
      <Header>
        Hire Us
      </Header>
      <CTA>Whether you’re still in the planning stage or already running an established company, 
      chances are we can help you out!
      Enter your email below and tell us a bit about your project needs, and we'll set up a free consultation.
      We can’t wait to hear from you!
      </CTA>
      <Row wrap="wrap">
        <FieldContainer>
          <TextInput
            id="email"
            name="email"
            onChange={handleChange}
            value={values.email || ''}
            backgroundColor='rgba(34, 216, 152, .2)'
            color='#333'
            placeholder="youremail@email.com"
            height="35px"
            fontSize="12px"
            borderNone={true}
          />
        </FieldContainer>
        <ButtonContainer flexDirection="column" justify="center">
          <Button
            buttonText="Let's Go!"
            borderRadius="4px"
            height="19px"
            handleClick={handleClick}
          />
        </ButtonContainer>
      </Row>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  max-width: 400px;

  > * {
    margin-bottom: 24px;
  }
`
const ButtonContainer = styled(FlexContainer)`
  @media (max-width: 479px) {
    justify-content: center;
  }
`

const FieldContainer = styled(FlexContainer)`
  background-color: #fff;
  border-radius: 8px;
  width: 250px;

  @media (max-width: 479px) {
    width: 200px;
  }
`
const Row = styled(FlexContainer)`
  > * {
    margin-right: 2px;
  }

  @media (max-width: 479px) {
    justify-content: center;
  }
  @media (max-width: 360px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
      margin-bottom: 12px;
    }
  }
`
const Header = styled.h4`
   color: #fff;
`
const CTA = styled.p`
  color: #cccccc;
  font-family: "Circular Std - Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`

export default FooterCTA;
