import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import FlexContainer from "../elements/FlexContainer";
import CloseIcon from "../elements/CloseIcon";

import Dialog from "@material-ui/core/Dialog";
// import DialogActions from '@material-ui/core/DialogActions';
import PortfolioHeader from "./PortfolioHeader";
import PortfolioFooter from "./PortfolioFooter";
import PortfolioScreenshot from "./PortfolioScreenshot";
import FakeFooter from "./FakeFooter";
import PortfolioSubsection from "./PortfolioSubsection";

const PortfolioModal = ({ open, handleClose, item, setItem, ...props }) => {
  const nodeRef = useRef();

  useEffect(() => {
    if (nodeRef.current) {
      nodeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [item]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
      <div
        ref={(node) => {
          nodeRef.current = node;
        }}
      >
        <Content>
          <CloseContainer>
            <CloseIcon handleClose={handleClose} />
          </CloseContainer>
          {/* Left column */}
          <FlexContainer flexBasis="75%" flexDirection="column">
            <PortfolioHeader
              backgroundImage={`${process.env.PUBLIC_URL}/images/backgrounds/panel1.png`}
              companyName={item.companyName || ""}
              summaryText={item.summaryText || ""}
              techUsed={item.techUsed || []}
            />
            <PortfolioScreenshot
              src={item.screenshot1 || ""}
              backgroundColor="#34a5e2"
            />
            {item.screenshot2 && (item.textHead1 || item.text1) && (
              <PortfolioRow justify="space-between">
                <PortfolioSubsection
                  size="small"
                  backgroundImage={`${process.env.PUBLIC_URL}/images/backgrounds/panel2.png`}
                  header={item.textHead1 || ""}
                  text={item.text1 || ""}
                />
                <PortfolioScreenshot
                  src={item.screenshot2 || ""}
                  size="small"
                  backgroundColor="#34a4a8"
                />
              </PortfolioRow>
            )}
            {item.screenshot3 && (item.textHead2 || item.text2) && (
              <PortfolioRow justify="space-between">
                <PortfolioScreenshot
                  src={item.screenshot3 || ""}
                  size="small"
                  backgroundColor="#2995da"
                />
                <PortfolioSubsection
                  size="small"
                  backgroundImage={`${process.env.PUBLIC_URL}/images/backgrounds/panel3.png`}
                  header={item.textHead2 || ""}
                  text={item.text2 || ""}
                />
              </PortfolioRow>
            )}
            {item.screenshot4 && (
              <PortfolioScreenshot
                src={item.screenshot4 || ""}
                backgroundColor="#34a5e2"
              />
            )}
            {(item.textHead3 || item.text3) && (
              <PortfolioSubsection
                backgroundImage={`${process.env.PUBLIC_URL}/images/backgrounds/panel4.png`}
                header={item.textHead3 || ""}
                text={item.text3 || ""}
              />
            )}
            {item.screenshot5 && (
              <PortfolioScreenshot
                src={item.screenshot5 || ""}
                backgroundColor="#34a5e2"
              />
            )}
            <FakeFooter /> {/* for spacing */}
            <PortfolioFooter
              backgroundImage={`${process.env.PUBLIC_URL}/images/backgrounds/panel5.png`}
              item={item}
              setItem={setItem}
            />
          </FlexContainer>
        </Content>
      </div>
    </Dialog>
  );
};

const Content = styled(FlexContainer)`
  background-color: rgba(38, 38, 94);
  position: relative;

  @media (max-width: 479px) {
    width: 100%;
  }
  /* background-image: url(${(props) => props.image}); */
  /* background-blend-mode: lighten; */
  /* backdrop-filter: blur(4) opacity(0.3); */
`;

const PortfolioRow = styled(FlexContainer)`
  width: 100%;
  height: 528px;

  @media (max-width: 479px) {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
`;

const CloseContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export default PortfolioModal;
