import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';

const BioBox = ({
  image,
  name,
  role,
  bio,
  social,
  ...props
}) => {

  return (
    <Container flexDirection="column" alignItems="center">
      <Image image={image} />
      <Name>{name}</Name>
      <Role>{role}</Role>
      {/* <Bio>{bio}</Bio> */}
      {/* <SocialContainer /> */}
    </Container>
  )
};

const Container = styled(FlexContainer)`
  width: 234px;
  /* height: 405px; */
  height: 280px;
  padding: 7px;
  box-shadow: 0 6px 6px rgba(173, 178, 199, 0.22);
  background-color: #fff;
  border-radius: 4px;
`
// const SocialContainer = styled(FlexContainer)`
//   width: 100%;
//   height: 40px;
//   border-top: 1px solid rgba(38, 38, 94, 0.1);
//   align-self: flex-end;
// `

const Image = styled.div`
  width: 234px;
  height: 234px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
`

const BoxText = styled.p`
  color: #334a5f;
  font-family: "Circular Std - Book";
  font-weight: 400;
  line-height: 16px;
`

const Name = styled(BoxText)`
  font-size: 18px;
  margin: 18px 0 8px 0;
`
const Role = styled(BoxText)`
  opacity: 0.55;
  text-align: center;
  font-size: 12px;
  margin-bottom: 18px;
`
// const Bio = styled(BoxText)`
//   font-size: 12px;
//   text-align: center;
//   width: 67%
// `

export default BioBox;
