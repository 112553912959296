import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import Checkmark from './Checkmark';

// credit to @yenteho for the model for this button: https://codesandbox.io/s/ymwo5419yv

const CheckBox = ({
  checked,
  name,
  handleToggle,
  label,
  ...props }) => (
  <Container alignItems="center"
    onClick={handleToggle}
    name={name}
    checked={checked}
    {...props}
  >
    <Item name={name}>
      <Box
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleToggle}
        {...props}
      />
      <CheckIcon />
    </Item>
    <CheckBoxLabel>{label}</CheckBoxLabel>
  </Container>
);

const Container = styled(FlexContainer)`
  box-sizing: border-box;
  position: relative;
  height: 50px;
  width: 45%;
  border: ${props =>
    props.checked ? `1px solid ${colors.mainYellow}` : `2px solid ${colors.mainBlue}`};
  background-color: rgba(38, 38, 94, 0.3);
  margin: 0;
  padding: 0 12px;
  font-size: 17px
  line-height: 20px
  border-radius: 4px;

  &:hover {
    border-bottom: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.mainYellow}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (max-width: 479px) {
    height: 75px;
    width: 80%;
  }
`;

const CheckIcon = styled(Checkmark)`
  fill: ${colors.mainYellow};
  height: 20px;
  width: auto;
  font-weight: 800;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 2px;
`

const CheckBoxLabel = styled.div`
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  user-select: none;
  ${'' /* text-align: left; */}
  ${'' /* margin-left: 36px; */}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  position: absolute;
`;

const Box = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 3px;
  width: 21px;
  height: 21px;

  &:checked + ${CheckIcon} {
    opacity: 1;
  }
`;

export default CheckBox;
