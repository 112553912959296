import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const CloseIcon = ({ handleClose, ...props }) => (
    <Icon
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      size={props.size}
      onClick={handleClose}
      {...props}
    >
      <g id="Style-Guide" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="Style-Guide---Elements"
          transform="translate(-198.000000, -5239.000000)"
          strokeWidth="1.5"
        >
          <g
            id="Remove-X-icon-Default"
            transform="translate(199.000000, 5240.000000)"
          >
            <g
              id="Group"
              transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) translate(6.000000, 6.000000)"
              strokeLinecap="round"
            >
              <path d="M0,6 L12,6" id="Line-2"></path>
              <path d="M6,0 L6,12" id="Line-2-Copy"></path>
            </g>
          </g>
        </g>
      </g>
    </Icon>
);

// const Circle = styled(FlexContainer)`
//   width: ${props => props.size || '32px'};
//   height: ${props => props.size || '32px'};
//   border-radius: 16px;

//   &:hover {
//     background-color: ${colors.primary100};
//   }

//   &:active {
//     background-color: ${colors.primary200};
//   }
// `;

const Icon = styled.svg`
  width: ${props => props.size || '32px'};
  height: ${props => props.size || '32px'};

  & circle {
    fill: none;
    stroke: white;
  }

  & path {
    stroke: white;
  }

  &:hover {
    cursor: pointer;
  }

  /* &:hover circle {
    fill: ${colors.primary050};
  }

  &:focus circle {
    fill: ${colors.primary050};
  }

  &:active circle {
    fill: ${colors.primary100};
  }

  &:disabled circle {
    fill: none;
    stroke: ${colors.primary300};
  } */
  &:disabled path {
    fill: none;
    stroke: ${colors.primary300};
  }
`;

export default CloseIcon;
