import React from 'react';
import styled from 'styled-components';
import FlexContainer from './FlexContainer';

import CheckBox from './CheckBox';

const CheckGroup = ({
  options,
  name,
  toggleValues,
  handleToggle,
  ...props
}) => {
  const buttons = options.map((option, index) => {
    return (
      <CheckBox
        key={index}
        label={option}
        name={option}
        checked={toggleValues[option] || false}
        handleToggle={handleToggle(name)}
      />
    )
  })


  return (
    <Container justify="center" wrap="wrap">
      {buttons}
    </Container>
  )
}

const Container = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
  > :nth-child(odd) {
    margin-right: 16px;
  }

  @media (max-width: 479px) {
    flex-direction: column;
    align-items: center;

    > :nth-child(odd) {
      margin-right: 0;
    }
  }
`

export default CheckGroup;
