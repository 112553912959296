import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

//fonts
import './fonts/CircularStd-Book.otf';
import './fonts/CircularStd-BookItalic.otf';
import './fonts/CircularStd-Medium.otf';
import './fonts/CircularStd-MediumItalic.otf';
import './fonts/MicroSquare-Regular.ttf';
import './fonts/MicroSquare-Regular.ttf';

const Xupe = (
      <Router>
        {/* <ErrorBoundary> */}
        <App />
        {/* </ErrorBoundary> */}
      </Router>
);

ReactDOM.render(Xupe, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
