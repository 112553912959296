import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';

const PortfolioScreenshot = ({
  src,
  size,
  backgroundColor,
  ...props
}) => {


  return (
    <Container size={size} color={backgroundColor}>
      <Img src={src} size={size} />
    </Container>
  )
};

const Container = styled(FlexContainer)`
  padding: ${props => props.size === 'small' ? '40px' : '80px'};
  max-width: 100%;
  background-color: ${props => props.color};

  @media (max-width: 479px) {
    padding: 40px;
    /* width: auto; */
  }
`;

const Img = styled.img`
  box-shadow: 0px 0px 20px black;
  max-width: ${props => props.size === 'small' ? null : '100%'};
  height: ${props => props.size === 'small' ? 'calc(528px - 80px)' : 'auto'}; 
  flex-shrink: ${props => props.size === 'small' ? '0' : 'inherit'};

  @media (max-width: 479px) {
    max-width: 100%;
    height: auto;
  }
`;

export default PortfolioScreenshot;
