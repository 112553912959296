import { useState } from 'react';

const useToggles = (initialValues = {}) => {
  const [toggleValues, setToggleValues] = useState(initialValues);

  const handleToggle = sub => e => {
    const name = e.currentTarget.attributes.name.value;
    if (!sub) {
      setToggleValues({ ...toggleValues, [name]: !toggleValues[name] });
    } else {
      const newSub = { ...toggleValues[sub], [name]: !toggleValues[sub][name] };
      setToggleValues({ ...toggleValues, [sub]: newSub });
    }
  };

  return {
    toggleValues,
    setToggleValues,
    handleToggle,
  };
};

export default useToggles;
