import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Testimonial from './Testimonial';

const SectionTestimonials = (props) => {

  return (
    <Container flexDirection="column" alignItems="center">
      <Caption>Testimonials</Caption>
      <Header>Clients Love Us</Header>
      <TestimonialGrid justify="center" flexDirection="column" alignItems="center">
        <Testimonial
          image={`${process.env.PUBLIC_URL}/images/testimonials/Whitney.png`}
          name="John Chesley"
          role="Founder"
          company="Nutriology"
          quote="Xupe takes a transparent and collaborative approach, 
          working with clients to ensure the product meets their expectations and ultimately lines up
           with their vision. Building a web app is a complex process but the experts at Xupe 
          have the knowledge and expertise to guide the process successfully from concept to launch."
        />
        <Testimonial
          image={`${process.env.PUBLIC_URL}/images/testimonials/Whitney.png`}
          name="Whitney Bateson"
          role="Owner"
          company="Whitney Bateson Digital Strategy"
          quote="They don’t just do what we tell them to do. Rather, 
          they provide input that is really valuable and helps us come up with a strategy."
        />
        <Testimonial
          image={`${process.env.PUBLIC_URL}/images/testimonials/Whitney.png`}
          name="Karen McGrail"
          role="Director"
          company="John C. Stalker Institute of Food and Nutrition"
          quote="The Xupe team are highly skilled professionals who have enhanced the security 
          and performance of our website, while consistently placing our best interests at the forefront. 
          Their responsive, smart, and logical strategy gives us the peace of mind that our website is secure, 
          up-to-date, and functioning the way we expect."
        />
      </TestimonialGrid>
    </Container>
  )
};

const Container = styled(FlexContainer)`
`

const TestimonialGrid = styled(FlexContainer)`
  margin-top: 24px;
  > * {
    margin-bottom: 24px;
  }
`
const Header = styled.h2`
  color: #fff;
`
const Caption = styled.h3`
  color: ${colors.mainYellow};
`

export default SectionTestimonials;
