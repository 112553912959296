import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.alignItems || 'stretch'};
  align-content: ${props => props.alignContent || 'stretch'};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`;

const FlexContainer = props => {
  return <Flex {...props}>{props.children}</Flex>;
};

export default FlexContainer;
