import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';

const Button = ({
  buttonText,
  handleClick,
  ...props
}) => {
  let safari = false;
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    safari = true;
  }

  return (
    <StyledButton onClick={handleClick} safari={safari} {...props} >
      {buttonText}
    </StyledButton>
  )
};


const StyledButton = styled(FlexContainer)`
  padding: 6px 9px;
  height: ${props => props.height || '14px'};
  border-radius: ${props => props.borderRadius || '16px'};
  width: 85px;
  background-color: ${colors.mainYellow};
  ${'' /* border: 1px solid ${colors.mainYellow}; */}
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: ${props => props.safari ? '0.01px' : '0.75px'};
  text-transform: uppercase;
  color: ${colors.mainBlue};
  font-weight: 600;
  box-shadow: 0 0 7px rgba(255, 218, 86, 0.25);

  &:hover {
    ${'' /* background-color: #fff; */}
    cursor: pointer;
    border: 1px solid #fff;
    padding: 5px 8px;
  }

  &:active {
    background-color: #fff;
  }

  @media (max-width: 360px) {
    border-radius: 16px;
    height: 14px;
  }
`;


export default Button;
