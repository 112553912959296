
export const colors = {
  mainBlue: '#111143', //
  altBlue: '#26265E',
  blue2: '#51517e',
  blue3: '#7d7d9e',
  blue4: '#a8a8bf',
  mainYellow: '#ffda56',
  yellow2: '#ffe178',
  yellow3: '#ffe99a',
  yellow4: '#fff0bb',
  grey1: '#333',
  grey2: '#666',
  grey3: '#999',
  black: '#000',
  error: '#FF7C0E',
  primary050: '#f8f8f8', //primary, grey
  primary100: '#e8e8e8',
  primary200: '#d8d8d8',
  primary300: '#d0d0d0',
  primary400: '#b8b8b8',
  primary500: '#a0a0a0',
  primary600: '#808080',
  primary700: '#686868',
  primary800: '#484848',
  primary900: '#282828',
};
