import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styleConstants';
// import FlexContainer from '../elements/FlexContainer';

// import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
// import IconButton from '@material-ui/core/IconButton';

const SiteNav = () => {
  const activeStyle = {
    borderBottom: `1px solid ${colors.mainYellow}`
  }
  return (
    <Nav alignItems="center">
      <LeftNav alignItems="center">
        <NavLink exact to={'/'} activeClassName="active" >
          <HomeLogo src={`${process.env.PUBLIC_URL}/images/logos/logo-main.png`} />
        </NavLink>
      </LeftNav>
      <RightNav alignItems="center">
        <StyledLink exact to={'/'} activeStyle={activeStyle}>
          Home
        </StyledLink>
        <StyledLink to={'/portfolio'} activeClassName="active" activeStyle={activeStyle}>
          {' '}
          Portfolio{' '}
        </StyledLink>
        <ButtonLink to={'/contact'}> Contact </ButtonLink>
        {/* <Circle>
          <StyledButton disableRipple disableFocusRipple size="small">
            <MenuRoundedIcon />
          </StyledButton>
        </Circle> */}
      </RightNav>
    </Nav>
  )
};


const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 0 150px;
  position: relative;
  z-index: 5;
  flex-shrink: 0;

  @media (max-width: 479px) {
    justify-content: center;
    padding: 20px 20px;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }

`;

const LeftNav = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 56px;
  }

  @media (max-width: 479px) {
    justify-content: center;
    margin: 0 56px;
  }
`;

const RightNav = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  >:not(:first-child) {
    margin-left: 56px;
  }

  @media (max-width: 479px) {
    /* display: none; */
  }
`;

const StyledLink = styled(NavLink)`
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.13px;
  padding-bottom: 2px;
  transform: translate(0, 2px);

  &:visited {
    color: #fff;
  }

  &:hover {
    cursor: pointer;
    opacity: .7;
  }

  &:active {
    border-bottom: 1px solid ${colors.mainYellow};
  }
`;


// const StyledButton = styled(IconButton)`
//   & .MuiSvgIcon-root {
//     fill: ${colors.mainBlue} !important;
//     stroke: ${colors.mainBlue} !important;
//     width: 18px;
//     height: 18px;
//   }
// `

// const Circle = styled(FlexContainer)`
//   width: 32px;
//   height: 32px;
//   border-radius: 16px;
//   text-align: center;
//   background-color: #fff;
//   justify-content: center;
//   align-items: center;

//   /* display: none; */

//   &:hover {
//     background-color: #fff;
//     cursor: pointer;
//   }

//   &:active {
//     background-color: #ccc;
//   }
// `;

const ButtonLink = styled(NavLink)`
  padding: 5px;
  border-radius: 16px;
  width: 85px;
  background-color: ${colors.mainYellow};
  border: 1px solid #fff;
  text-align: center;
  font-size: 11px;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  color: ${colors.mainBlue};
  font-weight: 600;

  &:hover {
    background-color: #fff;
    cursor: pointer;
  }

  @media (max-width: 479px) {
    display: none;
  }
`;

const HomeLogo = styled.img`
  width: 126px;
  margin-top: 4px;

  @media (max-width: 479px) {
    width: 200px;
  }
`;

//font-family: "Raleway";

export default SiteNav;
