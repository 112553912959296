import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import FooterCTA from './FooterCTA';
// import Button from '../elements/Button';

const Footer = props => {

  return (
    <Container justify="space-between">
      <CompanyInfoContainer flexDirection="column">
        <HomeLogo src={`${process.env.PUBLIC_URL}/images/logos/logo-main.png`}  />
        <Bio>Planning, designing, and developing digital solutions custom-built for your business.</Bio>
        {/* <SocialLinksRow></SocialLinksRow> */}
      </CompanyInfoContainer>
      {/* <ResourcesContainer flexDirection="column" >
        <Header>Resources</Header>
        <LinksContainer flexDirection="column" wrap="wrap">
          <Link href="#">Home</Link>
          <Link href="#">Services</Link>
          <Link href="#">Our News</Link>
          <Link href="#">Careers</Link>
          <Link href="#">Customers</Link>
          <Link href="#">Our Team</Link>
        </LinksContainer>
      </ResourcesContainer> */}
      <NewsletterContainer>
        <FooterCTA {...props} />
      </NewsletterContainer>

    </Container>
  )
};

const Container = styled(FlexContainer)`
  padding: 60px 150px;

  @media (max-width: 768px) {
    padding: 30px 70px;
  }

  @media (max-width: 479px) {
    flex-wrap: wrap;
    padding: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`

const CompanyInfoContainer = styled(FlexContainer)`
  max-width: 160px;

  @media (max-width: 479px) {
    display: none;
  }
`
const Bio = styled.p`
  color: #cccccc;
  font-family: "Circular Std - Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`
// const Link = styled.a`
//   color: #cccccc;
//   font-family: "Circular Std - Book";
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 30px;
//   margin-right: 60px;
// `
// const SocialLinksRow = styled(FlexContainer)`
//
// `
// const ResourcesContainer = styled(FlexContainer)`
//   max-width: 170px;
//
//   > * {
//     margin-bottom: 24px;
//   }
// `
//
// const LinksContainer = styled(FlexContainer)`
//   max-height: 90px;
// `
//
// const Header = styled.h4`
//   color: #fff;
// `

const NewsletterContainer = styled(FlexContainer)`

`

const HomeLogo = styled.img`
  width: 100px;
`;


export default Footer;
