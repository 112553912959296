import React from "react";
import styled from "styled-components";
import FlexContainer from "../elements/FlexContainer";

import Button from "../elements/Button";
import useReactRouter from "use-react-router";

const Hero = (props) => {
  const { history } = useReactRouter();

  return (
    <Container alignItems="center" justify="space-between">
      <HeroContent flexDirection="column" justify="flex-start">
        <Header>Making Apps Take Flight</Header>
        <TagLine>
          Design, development, and everything in-between. Because greatness
          doesn't come from a template.
        </TagLine>
        <Button
          buttonText="Hire Us"
          handleClick={() => history.push("/contact")}
        />
      </HeroContent>
    </Container>
  );
};

// We grow apps from dream to reality, shepherding your ideas
//   through design and development and supporting them through long-term
// maintenance and optimization.

const Container = styled(FlexContainer)`
  position: relative;
  padding: 0 150px;
  height: 70vh;

  @media (max-width: 768px) {
    height: 40vh;
    justify-content: center;
  }

  @media (max-width: 479px) {
    height: 70vh;
    padding: 0 20px;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const HeroContent = styled(FlexContainer)`
  float: left;
  flex-basis: 50%;

  > :nth-child(2) {
    margin: 16px 0 32px 0;
  }

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-basis: 90%;
  }
`;

const TagLine = styled.p`
  color: #fff;
  width: 70%;
  opacity: 0.8;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 479px) {
    text-align: center;
    ${"" /* width: 100%; */}
  }
`;

const Header = styled.h1`
  color: #fff;
`;

export default Hero;
