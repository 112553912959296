import axios from 'axios';
import qs from 'qs';

export async function apiCall(method, path, data) {
  try {
    let url = path;
    if (method.toLowerCase() === 'get') {
      if (data && data.query) {
        data.query = JSON.stringify(data.query);
      }
      if (data && data.populate) {
        data.populate = JSON.stringify(data.populate);
      }
      const payloadString = qs.stringify(data);
      url = `${url}?${payloadString}`;
    }
    if (method.toLowerCase() === 'put') {
      let dataURL = {};
      if (data && data.populate) {
        dataURL.populate = JSON.stringify(data.populate);
      }
      url = `${url}?${qs.stringify(dataURL)}`;
    }
    const res = await axios[method.toLowerCase()](url, data);
    return res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      throw err.response.data;
    }
    throw err;
  }
}

export function createNewContact(params) {
  return apiCall('post', `/create_contact`, params);
}

export function updateContact(params) {
  return apiCall('post', `/update_contact`, params);
}
