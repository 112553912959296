import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Checkmark from '../elements/Checkmark';

const PortfolioHeader = ({
  backgroundImage,
  companyName,
  summaryText,
  techUsed,
  ...props
}) => {

  const techItems = techUsed.map((tech, index) => (
    <TechItem key={index}>
      <TechCheck />
      <Tech>{tech}</Tech>
    </TechItem>
  ))

  return (
    <Container justify="space-between" image={backgroundImage}>
      <LeftColumn flexDirection="column" alignItems="flex-start">
        <Caption>Case Study</Caption>
        <Title>{companyName}</Title>
      </LeftColumn>
      <RightColumn flexDirection="column" alignItems="flex-start">
        <Text>{summaryText}</Text>
        <TechContainer>
          <TechLabel>Built in:</TechLabel>
          <TechList wrap="wrap">{techItems}</TechList>
        </TechContainer>
      </RightColumn>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  padding: 90px;
  background-image: url(${props => props.image});

  @media (max-width: 479px) {
    width: auto;
    padding: 40px;
    flex-wrap: wrap;
    > * {
      margin-bottom: 24px;
    }
  }
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 35%;

  @media (max-width: 479px) {
    flex-basis: 100%;
  }
`;

const RightColumn = styled(FlexContainer)`
  flex-basis: 55%;

  @media (max-width: 479px) {
    flex-basis: 100%;
  }
`;

const Title = styled.h1`
  color: white;

  @media (max-width: 479px) {
    flex-basis: 90%;
  }
`;

const Text = styled.h4`
  color: white;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 479px) {
    flex-basis: 60%;
    line-height: 24px;
  }
`;

const Caption = styled.h3`
  color: ${colors.mainYellow};
`;

const TechLabel = styled.h4`
  color: ${colors.mainYellow};
  font-size: 16px;
  flex-basis: 20%;
  min-width: 75px;
`;

const TechItem = styled(FlexContainer)`
  >:first-child {
    margin-right: 8px;
  }
`
const Tech = styled.h4`
  color: ${colors.mainYellow};
  text-align: left;
  /* font-weight: bold; */
`

const TechCheck = styled(Checkmark)`
  fill: ${colors.mainYellow};
  height: 20px;
  width: auto;
  font-weight: 800;
`

const TechContainer = styled(FlexContainer)`
  margin: 16px 16px 0 0;

  @media (max-width: 479px) {
    margin: 24px 16px 0 0;
  }
`;

const TechList = styled(FlexContainer)`
  > * {
    margin-right: 12px;
    margin-bottom: 8px;
  }

  @media (max-width: 479px) {
    flex-direction: column;
    max-height: 120px;
  }
`;

export default PortfolioHeader;
