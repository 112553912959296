import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';

const RadioInput = ({
  checked,
  value,
  handleChange,
  handleRadio,
  name,
  label,
  ...props }) => {
    const handleClick = () => {
      handleRadio(name, value);
    }
    return (
      <Container alignItems="center" justify="flex-start"
        onClick={handleClick}
        checked={checked}
        {...props}
      >
        <Item
          onClick={handleClick}
        >
          <RadioButton
            type="radio"
            onChange={handleChange}
            checked={checked}
            value={value}
            name={name}
            {...props}
          />
          {/* <RadioButtonStyle checked={checked} /> */}
        </Item>
        <RadioButtonLabel>{label || value}</RadioButtonLabel>
      </Container>
    )
  };



  const Item = styled.div`
    display: flex;
    align-items: center;
    height: 16px;
    width: 16px;
    ${'' /* height: 48px; */}
    position: absolute;
  `;

  const RadioButtonStyle = styled.label`
    position: absolute;
    top: 0%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 1px solid ${colors.primary500};

    &:checked  {
      background: ${colors.secondary500};
      opacity: 1;
      border: 1px solid ${colors.secondary600};
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 4px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `;

  const RadioButton = styled.input`
    position: absolute;
    top: 0%;
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
    }
    &:hover ~ ${RadioButtonStyle} {
      background: ${colors.mainYellow};
      opacity: 0.5;
      border: 1px solid ${colors.mainBlue};

      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 4px;
        background: white;
      }
    }
    &:focus + ${RadioButtonStyle} {
      border: 1px solid ${colors.mainBlue};
    }
    &:checked + ${RadioButtonStyle} {
      background: ${colors.mainYellow};
      opacity: 1;
      border: 1px solid ${colors.mainBlue};
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 4px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `;

const Container = styled(FlexContainer)`
  box-sizing: border-box;
  height: 50px;
  width: 45%;
  border: ${props =>
    props.checked ? `1px solid ${colors.mainYellow}` : `2px solid ${colors.mainBlue}`};
  background-color: rgba(38, 38, 94, 0.3);
  margin: 0;
  padding: 0 12px;
  font-size: 17px
  line-height: 20px
  border-radius: 4px;

  &:hover {
    border-bottom: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.mainYellow}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (max-width: 479px) {
    height: 75px;
    width: 80%;
  }

`;
const RadioButtonLabel = styled.div`
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  user-select: none;
  ${'' /* text-align: left; */}
  ${'' /* margin-left: 36px; */}
`;

export default RadioInput;
