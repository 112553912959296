import React from 'react';
import styled from 'styled-components';
import './button.css';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import SiteNav from '../components/SiteNav';

const ThankYouPage = props => {

  return (
    <Background src={`${process.env.PUBLIC_URL}/images/backgrounds/contact-background.png`}>
      <SiteNav />
      <ThanksContainer flexDirection="column" justify="center" alignItems="center">
        <Message>Thank you for your interest in working with Xupe!</Message>
        <Message>We will be in touch within 48 hours to discuss how we can
        best help you with your project needs.</Message>
      </ThanksContainer>
    </Background>
  )
};


const Background = styled.div`
  min-height: 100vh;
  background-image: ${props => `url(${props.src})`};
  background-color: ${colors.mainBlue};
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: content;
  ${'' /* overflow-y: scroll; */}
`

const ThanksContainer = styled(FlexContainer)`
  margin: 0 auto;
  width: 75%;
  height: 80vh;

  ${'' /* style the generated typeform elements */}
  > * {
    width: 75%;
  }

  @media (max-width: 479px) {
    height: 100%;
    margin-top: 36px;
  }
`

const Message = styled.h2`
  color: #fff;
  margin-bottom: 18px;
  text-align: center;

  @media (max-width: 479px) {
    font-size: 20px;
  }
`

export default ThankYouPage;
