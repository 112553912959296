import React, { useState } from 'react';
import styled from 'styled-components';
import './button.css';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Typeform from 'react-typeform';
import SiteNav from '../components/SiteNav';
import Button from '../elements/Button';

import useFormValues from '../hooks/useFormValues';
import useToggles from '../hooks/useToggles';
import useReactRouter from 'use-react-router';

import TextInput from '../elements/TextInput';
import TextBox from '../elements/TextBox';
import RadioGroup from '../elements/RadioGroup';
import CheckGroup from '../elements/CheckGroup';

import { createNewContact, updateContact } from '../api.js';

const ContactPage = ({ setBackground, email, ...props }) => {
  const { history } = useReactRouter();

  const initialValues = { id: '', email: email || '' };

  const { values, handleChange } = useFormValues(initialValues);

  const initialToggleValues = {
    services: {},
    source: {},
  };

  const { toggleValues, handleToggle } = useToggles(initialToggleValues);

  const [errors, setErrors] = useState(false);

  // const manageErrors = () => {
  //   if (!values.email || (id && !values.name)) {
  //     setErrors(true);
  //   } else {
  //     setErrors(false);
  //   }
  // };

  // const handleCreate = () => {
  //   if (errors || !values.email) {
  //     return;
  //   }
  //   createNewContact(values)
  //     .then((result) => {
  //       setId(result);
  //     })
  //     .catch((err) => console.error(err));
  // };

  const handleSubmit = () => {
    if (errors) {
      return;
    }
    const payload = { ...values, ...toggleValues };

    createNewContact(payload)
      .then((result) => {
        history.push("/thankyou");
      })
      .catch((err) => console.error(err));
  };

  return (
    <Background
      src={`${process.env.PUBLIC_URL}/images/backgrounds/contact-background.png`}
    >
      <SiteNav />
      <Header>Contact Us</Header>
      <QuestionsContainer justify="center" alignItems="center">
        <form>
            <Question flexDirection="column">
              <QuestionText>
                Email
              </QuestionText>
              <TextInput
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email || ""}
                placeholder="Type Here..."
                required
              />
            </Question>
            <Question flexDirection="column">
              <QuestionText>
                Your Name
              </QuestionText>
              <TextInput
                id="name"
                name="name"
                onChange={handleChange}
                value={values.name || ""}
                placeholder="Type Here..."
                required
              />
            </Question>
            <Question flexDirection="column">
              <QuestionText>Company Name</QuestionText>
              <TextInput
                id="company"
                name="company"
                onChange={handleChange}
                value={values.company || ""}
              placeholder="Type Here..."
              required
              />
            </Question>
            <Question flexDirection="column">
              <QuestionText>
              Please tell us a little about your project and what your biggest needs/challenges are
              </QuestionText>
              <TextBox
                id="projectBrief"
                name="projectBrief"
                onChange={handleChange}
                value={values.projectBrief || ""}
                placeholder="Type Here..."
                required
              />
          </Question>
          <Button
            type="submit"
            buttonText="Submit"
            handleClick={handleSubmit}
            style={{ float: 'right' }} />
        </form>
        
        {errors && (
                <ErrorMessage>
                  Please fill out required fields and submit again.
                </ErrorMessage>
              )}
      </QuestionsContainer>
    </Background>
  );
};

const Background = styled.div`
  min-height: 100vh;
  background-image: ${(props) => `url(${props.src})`};
  background-color: ${colors.mainBlue};
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: content;
  ${"" /* overflow-y: scroll; */}
`;

const Header = styled.h1`
  color: #fff;
  text-align: center;
  margin: 24px 0;
`;

const QuestionsContainer = styled(FlexContainer)`
  margin: 0 auto;
  width: 75%;
  padding-bottom: 64px;

  ${"" /* style the generated typeform elements */}
  > * {
    width: 75%;
  }

  @media (max-width: 479px) {
    height: 100%;
    margin-top: 36px;
  }
`;

const Question = styled(FlexContainer)`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;

const QuestionText = styled.h4`
  color: #fff;
  margin-bottom: 12px;
  text-align: left;

  @media (max-width: 479px) {
    font-size: 20px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 11px;
  margin-top: 12px;
  color: ${colors.mainYellow};
`;


export default ContactPage;
