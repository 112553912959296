import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const PortfolioItem = ({
  image,
  companyName,
  projectType,
  handleClick,
  openModal,
  item,
  ...props
}) => {

  return (
    <Container flexDirection="column" >
      <Image src={image} onClick={() => openModal(item)} />
      <CompanyName>{companyName}</CompanyName>
      <ProjectType>{projectType}</ProjectType>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  margin: 18px;

  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(10, 10, 10, 0.5);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;

  @media (max-width: 479px) {
    transform: none;

    &:hover {
      transform: none;
    }
  }

  /* &:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
   
    transition-duration: 0.3s;
    transition-property: transform, opacity;
  }
   */
  &:hover {
    transform: scale(1.1);    
  }
  /* &:hover:before {
    transform: translateY(5px);
    opacity: 1;
  } */
  
`
const Image = styled.img`
  max-width: 250px;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 10px 15px 15px -10px rgba(0,0,0, 0.5);

  &:hover {
    cursor: pointer;
  }

`

const CompanyName = styled.p`
  font-size: 16px;
  margin-top: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 28px;
  color: #fff;
  /* color: ${colors.mainYellow}; */
  letter-spacing: 2.13px;
  /* width: 25%; */
  
`
const ProjectType = styled.p`
  font-size: 11px;
  letter-spacing: 1.87px;
  text-transform: uppercase;
  /* color: #fff; */
  color: ${colors.mainYellow};
  /* width: 20%; */
`

export default PortfolioItem;
