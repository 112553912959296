import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const FeatureBox = ({
  header,
  text,
  icon,
  ...props
}) => {

  return (
    <Container flexDirection="column" alignItems="center" justify="space-between">
      <Icon src={icon} />
      <Header>{header}</Header>
      <Text>{text}</Text>
    </Container>
  )
};

const Container = styled(FlexContainer)`
  width: 196px;
  height: 213px;
  padding: 26px;
  background-color: #fff;
  border-radius: 12px;

  ${'' /* @media (max-width: 768px) {
    padding: 18px;
  } */}
`

const Icon = styled.img`
  width: 85px;
`

const Header = styled.h4`
  color: ${colors.grey1};
  font-weight: 600;
`
const Text = styled.h5`
  color: ${colors.grey1};
  text-align: center;
  font-weight: 500;
  font-size: 14px;
`

export default FeatureBox;
