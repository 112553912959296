import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
// import Avatar from '../elements/Avatar';

const Testimonial = ({
  name,
  role,
  company,
  image,
  quote,
  ...props
}) => {

  return (
    <Container flexDirection="column">
      <Quote>
        {quote}
      </Quote>
      <SourceContainer>
        <NameContainer flexDirection="column" alignItems="flex-end">
          <Name>{name}</Name>
          <Role>{role}, <Company>{company}</Company></Role>
        </NameContainer>
        {/* <Avatar src={image} /> */}
      </SourceContainer>
    </Container>
  )
}

const Container = styled(FlexContainer)`
  max-width: 75%;
`

const SourceContainer = styled(FlexContainer)`
  margin-top: 16px;
  align-self: flex-end;
`

const NameContainer = styled(FlexContainer)`
  margin-right: 16px;
`

const Quote = styled.h4`
  color: #fff;
  text-align: center;
  font-style: italic;
`
const Name = styled.h4`
  color: ${colors.mainYellow};
`
const Role = styled.p`
  color: ${colors.mainYellow};
  text-align: left;
  font-size: 13px;
`

const Company = styled.span`
  font-style: italic;
`

export default Testimonial;
