import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Checkmark from '../elements/Checkmark';

const SectionTechnologies = (props) => {

  return (
    <Container justify="space-between">
      <LeftContainer>
        <Image src={`${process.env.PUBLIC_URL}/images/icons/challenge.png`} />
      </LeftContainer>
      <RightContainer flexDirection="column" align-items="flex-start">
        <Header>Sign out of the past and into the future</Header>
        <Subhead>
          We stay up-to-date with the latest software and frameworks 
          so your apps will always be easy-to-use, 
          simple to maintain, and outperform the competition.
        </Subhead>
          <TechContainer wrap="wrap" flexDirection="column">
            <TechItem justify="flex-start">
              <TechCheck />
              <Tech>React</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>Node</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>Express</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>MongoDB</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>Python</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>PWAs</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>Docker</Tech>
            </TechItem>
            <TechItem>
              <TechCheck />
              <Tech>Google Cloud</Tech>
            </TechItem>
          </TechContainer>
      </RightContainer>
    </Container>
  )
};

const Container = styled(FlexContainer)`

  @media (max-width: 479px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`

const LeftContainer = styled(FlexContainer)`
  flex-basis: 50%;
  text-align: left;

  @media (max-width: 479px) {
    text-align: center;
    justify-content: center;
  }
`
const RightContainer = styled(FlexContainer)`
  flex-basis: 50%;
  padding-left: 16px;

  > * {
    margin-bottom: 16px;
  }

  @media (max-width: 479px) {
    padding: 0;
    margin-top: 16px;
    flex-basis: 75%;

  }
`

// const SpacingContainer = styled(FlexContainer)`
//   @media (max-width: 479px) {
//     justify-content: center;
//   }
// `

const Header = styled.h2`
  color: #fff;
  text-align: left;
  margin: 12px 0;

  @media (max-width: 479px) {
    text-align: center;
  }
`

const Subhead = styled.h5`
  color: #fff;
  text-align: left;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 479px) {
    text-align: center;

  }
`
const TechCheck = styled(Checkmark)`
  fill: ${colors.mainYellow};
  height: 20px;
  width: auto;
  font-weight: 800;
`
const TechContainer = styled(FlexContainer)`
  max-height: 120px;
  margin-top: 16px;
  > * {
    margin-bottom: 8px;
  }

  @media (max-width: 479px) {
    text-align: center;
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    > * {
      margin-right: 50px;
    }
  }
`
const TechItem = styled(FlexContainer)`
  > * {
    margin-right: 8px;
  }
`
const Tech = styled.h4`
  color: ${colors.mainYellow};
  text-align: left;
`

const Image = styled.img`
  height: 348px;
  width: 360px;
  z-index: 100;

  @media (max-width: 768px) {
    height: 290px;
    width: 300px;
  }
`

export default SectionTechnologies;
