import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import ProjectBox from '../components/ProjectBox';

import { portfolioItems } from '../helpers/portfolioItems';

const PortfolioFooter = ({
  backgroundImage,
  item,
  setItem,
  ...props
}) => {
  const items = portfolioItems
    .filter(portfolioItem => item.companyName !== portfolioItem.companyName);

  const randomItems = [];
  const numItems = items.length < 3 ? items.length : 3;
  for (let i = 0; i < numItems; i++) {
    const randomIndex = Math.floor(Math.random() * items.length);
    const itemToPush = items.splice(randomIndex, 1)
    randomItems.push(itemToPush[0]);
  }

  const handleProjectClick = (nextProject) => {
    setItem(nextProject)
  }

  const projectBoxes = randomItems.map((item, index) => (
    <ProjectBox
      key={index}
      image={item.thumbnail}
      companyName={item.companyName}
      projectType={item.projectType}
      onClick={() => handleProjectClick(item)}
    />
    ))

  return (
    <Container
      image={backgroundImage}
      flexDirection="column"
      justify="flex-end"
      alignItems="center">
      {projectBoxes.length > 0 && (
        <>
         <Header>More Projects</Header>
         <ProjectContainer>
           {projectBoxes}
          </ProjectContainer>
        </>
      )}
     
    </Container>
  )
};

const Container = styled(FlexContainer)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* flex-basis: 40%; */

  @media (max-width: 479px) {
    display: none;
  }
`;

const ProjectContainer = styled(FlexContainer)`
  >:not(:last-child) {
    margin-right: 16px;
  }
`;

const Header = styled.h3`
  color: ${colors.mainYellow};
  margin-bottom: 16px;
`;

export default PortfolioFooter;
