import React, { useState } from "react";
import styled from "styled-components";
import FlexContainer from "../elements/FlexContainer";
import { colors } from "../styleConstants";
import SiteNav from "../components/SiteNav";
import PortfolioItem from "../components/PortfolioItem";
import PortfolioModal from "../portfolio/PortfolioModal";
import { portfolioItems } from "../helpers/portfolioItems";
import Footer from "../components/Footer";

const PortfolioPage = ({ ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});

  const openModal = (item) => {
    setItem(item);
    setModalOpen(true);
  };

  const closeModalItem = () => {
    setModalOpen(false);
    setItem({});
  };

  const portfolioThumbnails = portfolioItems.map((item) => (
    <PortfolioItem
      key={item.id}
      image={item.thumbnail}
      companyName={item.companyName}
      projectType={item.projectType}
      item={item}
      openModal={openModal}
    />
  ));

  return (
    <Background
      src={`${process.env.PUBLIC_URL}/images/backgrounds/portfolio-background.png`}
    >
      <SiteNav />
      <Content alignItems="center" flexDirection="column">
        <HeaderContainer
          flexDirection="column"
          justify="center"
          alignItems="center"
        >
          <Caption>Our Portfolio</Caption>
          <Header>We Made Their Dreams a Reality. Now It's Your Turn.</Header>
        </HeaderContainer>
        <BodyContainer flexDirection="column" alignItems="center">
          {/* <FilterBar justify="center" alignItems="center">
            <FilterLink>All</FilterLink>
            <FilterLink>Design</FilterLink>
            <FilterLink>Web Apps</FilterLink>
            <FilterLink>Mobile Apps</FilterLink>
          </FilterBar> */}
          <PortfolioGrid
            flexDirection="column"
            wrap="wrap"
            alignContent="center"
          >
            {portfolioThumbnails}
          </PortfolioGrid>
          <PortfolioModal
            open={modalOpen}
            item={item}
            setItem={setItem}
            handleClose={closeModalItem}
          />
        </BodyContainer>
      </Content>
      <Footer {...props} />
    </Background>
  );
};

const Background = styled.div`
  min-height: 100vh;
  background-image: ${(props) => `url(${props.src})`};
  background-color: ${colors.mainBlue};
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: content;
  ${"" /* overflow-y: scroll; */}
`;

const Content = styled(FlexContainer)`
  > * {
    padding: 75px 150px;
  }

  @media (max-width: 768px) {
    > * {
      padding: 50px 70px;
    }
  }

  @media (max-width: 479px) {
    > * {
      padding: 50px 20px;
    }
    max-height: 100vh;
    overflow: scroll;
    padding-bottom: 100px;
  }
`;

const HeaderContainer = styled(FlexContainer)`
  padding: 140px 0 70px 0;
`;

const BodyContainer = styled(FlexContainer)``;

const PortfolioGrid = styled(FlexContainer)`
  max-height: 1100px;
  width: 80%;

  @media (max-width: 479px) {
    /* max-height: 110; */
    /* overflow: scroll; */
    flex-wrap: nowrap;
    margin-top: -50px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    width: auto;
  }
`;
const Header = styled.h1`
  color: #fff;
  text-align: center;

  @media (max-width: 479px) {
    width: 80%;
  }
`;

const Caption = styled.h3`
  color: ${colors.mainYellow};
`;

// const FilterBar = styled(FlexContainer)`
//   max-width: 400px;

//   > * {
//     margin-right: 12px;
//   }
// `

// const FilterLink = styled.p`
//   font-size: 11px;
//   opacity: 0.7;
//   color: #fff;
//   font-family: "Circular Std - Book";
//   font-size: 11px;
//   font-weight: 400;
//   line-height: 13.48px;
//   text-transform: uppercase;
//   letter-spacing: 2.13px;
// `

// const TagLine = styled.p`
//   color: #fff;
//   width: 70%;
//   opacity: 0.8;
//   font-size: 13px;
//   line-height: 1.5;
// `

export default PortfolioPage;
